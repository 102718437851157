import axios from "axios";
import { token } from "../../../constants";

const sendEmail = async (emailTo = "", subject = "", htmlContent = "") => {
  try {
    const options = {
      url: "https://api-externa.inteegra.com.br/email/api/emails",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: {
        to: [emailTo],
        from: "naoresponder@inteegra.com.br",
        subject,
        htmlContent,
      },
    };
    await axios(options);
  } catch (error) {
    console.error("Error sendEmail", error);
    throw error;
  }
};

export default sendEmail;
