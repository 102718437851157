// Máscara CNPJ
export const maskCNPJ = (value) => {
  return value
    .replace(/\D/g, "")
    .replace(/^(\d{2})(\d)/, "$1.$2")
    .replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
    .replace(/\.(\d{3})(\d)/, ".$1/$2")
    .replace(/(\d{4})(\d)/, "$1-$2")
    .slice(0, 18);
};

// Validação para CNPJ
export const validateCNPJ = (cnpj) => {
  const cleanedCNPJ = cnpj.replace(/\D/g, "");

  if (cleanedCNPJ.length !== 14) return false;

  const regex = /^\d{14}$/;
  return regex.test(cleanedCNPJ);
};

// Validação para email
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export function isValidEmail(email) {
  return emailRegex.test(email);
}

// Máscara para CEP -> 12345-678
export function formatCep(cep) {
  const cleanCep = cep.replace(/\D/g, "");

  if (cleanCep.length < 8) {
    return cleanCep;
  }

  return cleanCep.replace(/(\d{5})(\d{3})/, "$1-$2");
}

// Máscara para CPF -> 123.456.789.10
export function formatCpf(cpf) {
  const cleanCpf = cpf.replace(/\D/g, "");

  if (cleanCpf.length <= 3) {
    return cleanCpf;
  } else if (cleanCpf.length <= 6) {
    return cleanCpf.replace(/(\d{3})(\d{1,3})/, "$1.$2");
  } else if (cleanCpf.length <= 9) {
    return cleanCpf.replace(/(\d{3})(\d{3})(\d{1,3})/, "$1.$2.$3");
  } else {
    return cleanCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{1,2})/, "$1.$2.$3-$4");
  }
}

// Formatação de número telefone -> (11) 98888-0000
export const formatPhoneNumber = (value) => {
  const cleaned = value.replace(/\D/g, "");

  if (cleaned.length === 0) {
    return "";
  }
  if (cleaned.length <= 2) {
    return `(${cleaned}`;
  }
  if (cleaned.length <= 6) {
    return `(${cleaned.slice(0, 2)}) ${cleaned.slice(2)}`;
  }
  return `(${cleaned.slice(0, 2)}) ${cleaned.slice(2, 7)}-${cleaned.slice(
    7,
    11
  )}`;
};
