import axios from "axios";

function removeNonNumericCharacters(cnpj) {
  return cnpj.replace(/[^\d]/g, "");
}

export const getDataLogin = async (cnpj) => {
  try {
    const response = await axios.get(
      "https://api-externa.inteegra.com.br/public/api/admin/guests",
      {
        params: {
          ContractId: 2042,
          EventId: 141254,
          Email: `${removeNonNumericCharacters(cnpj)}@email.com.br`,
        },
        headers: {
          accept: "*/*",
        },
        maxBodyLength: Infinity,
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(error.response?.data || "Erro desconhecido");
  }
};

// const main = async () => {
//   const cnpj = "30.986.209/0001-37";
//   const formattedResponse = await getDataLogin(cnpj);
//   if (formattedResponse) {
//     return;
//     // console.log(formattedResponse);
//   }
// };

// main();
