import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./assets/scss/theme.scss";

import * as Sentry from "@sentry/react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Login from "./pages/login";

import Home from "./pages/home";
import { useAuth } from "./context/AuthenticationContext";

Sentry.init({
  dsn: "https://e8f1e2a0c72f45a18c2177c1f7861c8a@o1192043.ingest.us.sentry.io/4506898228510720",
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  maxMessageLength: 10000,
  normalizeDepth: 10,
});

function App() {
  const { isAuthenticated } = useAuth();

  return (
    <BrowserRouter>
      <Routes>
        {!isAuthenticated && <Route path="/" element={<Login />} />}

        <Route
          path="/cadastro"
          element={isAuthenticated ? <Home /> : <Navigate to={"/"} />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
