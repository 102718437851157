import BannerMail from "../../../assets/images/banner-lp-abras.webp"

export function getTemplatePending(guestName, companionName) {
  return `
    <!DOCTYPE html>
    <html lang="pt-BR" xmlns="http://www.w3.org/1999/xhtml">
    <head>
        <meta charset="utf-8" />
        <style type="text/css">
            body {
                font-family: Verdana, sans-serif;
                line-height: 1.6;
                color: #000;
            }
            .container {
                width: 100%;
                max-width: 600px;
                margin: 0 auto;
                padding: 20px;
                background-color: #f9f9f9;
                border: 1px solid #ddd;
                border-radius: 5px;
            }
            .header {
                text-align: center;
                padding-bottom: 20px;
                color: #000;
            }
            .content {
              padding: 20px;
              background-color: #fff;
              border: 1px solid #ddd;
              color: #000;
              border-radius: 5px;
              gap: 10px;
            }
            .title {
              font-size: 11px;
            }
            .name {
              font-size: 11px;
              color: #000;
              font-weight: 600;
            }
            .img-banner {
              width: 100%;
              height: 213px;
            }
            .img-qrcode {
              width: 150px;
              height: 150px;
            }
            .footer {
              text-align: center;
              padding-top: 20px;
              font-size: 12px;
              color: #777;
            }
            .ticket-note {
              text-align: left;
              font-weight: bold;
              font-size: 11px;
            }
        </style>
    </head>

    <body>
        <div class="container">
            <div class="header">
                <img class="img-banner" alt="Banner"
                  src="${BannerMail}"
                />
            </div>
            <div class="content">
                <h1 class="title">
                  Inscrição pendente, em breve entraremos em contato.
                </h1>


                <p class="name">Convidado: </p>

                <p class="name">${guestName}</p>

                <p class="name">Acompanhante: </p>

                <p class="name">${companionName}</p>

            </div>
            <div class="footer">
                <p>&copy; 2024 ABRAS. Todos os direitos reservados.</p>
            </div>
        </div>
    </body>
  </html>
  `;
}

export function getTemplateConfirmation(guestName, companionName) {
  return `
    <!DOCTYPE html>
    <html lang="pt-BR" xmlns="http://www.w3.org/1999/xhtml">
    <head>
        <meta charset="utf-8" />
        <style type="text/css">
            body {
                font-family: Verdana, sans-serif;
                line-height: 1.6;
                color: #000;
            }
            .container {
                width: 100%;
                max-width: 600px;
                margin: 0 auto;
                padding: 20px;
                background-color: #f9f9f9;
                border: 1px solid #ddd;
                border-radius: 5px;
            }
            .header {
                text-align: center;
                padding-bottom: 20px;
                color: #000;
            }
            .content {
              padding: 20px;
              background-color: #fff;
              border: 1px solid #ddd;
              color: #000;
              border-radius: 5px;
              gap: 10px;
            }
            .title {
              font-size: 11px;
            }
            .name {
              font-size: 11px;
              color: #000;
              font-weight: 600;
            }
            .img-banner {
              width: 100%;
              height: 213px;
            }
            .img-qrcode {
              width: 150px;
              height: 150px;
            }
            .footer {
              text-align: center;
              padding-top: 20px;
              font-size: 12px;
              color: #777;
            }
            .ticket-note {
              text-align: left;
              font-weight: bold;
              font-size: 11px;
            }
        </style>
    </head>

    <body>
        <div class="container">
            <div class="header">
                <img class="img-banner" alt="Banner"
                  src="${BannerMail}"
                />
            </div>
            <div class="content">
                <h1 class="title">
                  Inscrição confirmada, qualquer dúvida entre em contato.
                </h1>


                <p class="name">Convidado: </p>

                <p class="name">${guestName}</p>

                <p class="name">Acompanhante: </p>

                <p class="name">${companionName}</p>

            </div>
            <div class="footer">
                <p>&copy; 2024 ABRAS. Todos os direitos reservados.</p>
            </div>
        </div>
    </body>
  </html>
  `;
}

