export const handleCompanionDataSubmit = (
  companionData,
  setFormData,
  guestIndex
) => {
  setFormData((prevData) => {
    const newGuests = [...prevData.guests];
    newGuests[guestIndex] = {
      ...newGuests[guestIndex],
      companion: companionData,
    };
    return { ...prevData, guests: newGuests };
  });
};

export const handleFlightDataSubmit = (flightData, setFormData, guestIndex) => {
  setFormData((prevData) => {
    const newGuests = [...prevData.guests];
    newGuests[guestIndex] = {
      ...newGuests[guestIndex],
      flight: flightData,
    };
    return { ...prevData, guests: newGuests };
  });
};
