import { isValidEmail } from "../../../../utils/masks";

export async function validation(formData) {
  const seenEmails = new Set();
  let erro = null;

  // Função para checar os valores dos campos
  const checkField = (field, message) => {
    if (!field.value) {
      erro = message;
      return true;
    }
    return false;
  };

  // Função para validação dos convidados
  if (formData.guests && Array.isArray(formData.guests)) {
    for (let i = 0; i < formData.guests.length; i++) {
      const user = formData.guests[i];
      const baseMessage = `convidado ${i + 1}`;

      if (
        checkField(user.name, `Nome do ${baseMessage}`) ||
        checkField(user.badge, `Nome Crachá do ${baseMessage}`) ||
        checkField(user.cpf, `CPF do ${baseMessage}`) ||
        checkField(user.rg, `RG do ${baseMessage}`) ||
        checkField(user.telephone, `Telefone do ${baseMessage}`) ||
        checkField(user.birth, `Data de nascimento do ${baseMessage}`) ||
        checkField(user.gender, `Gênero do ${baseMessage}`) ||
        checkField(user.email, `E-mail do ${baseMessage}`) ||
        checkField(
          user.foodRestriction,
          `Selecione se você possui alguma restrição alimentar no ${baseMessage}`
        ) ||
        checkField(
          user.specificNeed,
          `Selecione se você possui alguma necessidade específica no ${baseMessage}`
        ) ||
        checkField(
          user.hasCompanion,
          `Selecione se você deseja levar um acompanhante em ${baseMessage}`
        ) ||
        checkField(
          user.needAir,
          `Selecione se você precisa de voo no ${baseMessage}`
        ) ||
        checkField(
          user.upgrade,
          `Selecione se você precisar de items extras no ${baseMessage}`
        ) ||
        checkField(user.retailWomen, `Mulheres do varejo do ${baseMessage}`)
      ) {
        break;
      }

      if (user.foodRestriction.value === "sim" && !user.whatRestriction.value) {
        erro = `Digite sua restrição alimentar no ${baseMessage}`;
        break;
      }

      if (user.specificNeed.value === "sim" && !user.whatNeed.value) {
        erro = `Digite sua necessidade específica no ${baseMessage}`;
        break;
      }

      if (user.email.value.trim() && !isValidEmail(user.email.value.trim())) {
        erro = `Digite um e-mail válido ${baseMessage}`;
        break;
      }

      if (seenEmails.has(user.email.value.trim())) {
        erro = `E-mail duplicado: ${user.email.value}`;
        break;
      }

      seenEmails.add(user.email.value);

      if (
        user.hasCompanion.value === "sim" &&
        !user.companion.nameCompanion.value
      ) {
        erro = `Dados do acompanhante do ${baseMessage}`;
        break;
      }

      if (
        user.email.value.trim() === user.companion.emailCompanion.value.trim()
      ) {
        erro = `O e-mail do convidado não pode ser igual ao do acompanhante: ${user.email.value}`;
        break;
      }

      if (user.needAir.value === "sim" && !user.flight.arrivalAirport.value) {
        erro = `Adicionar voo do ${baseMessage}`;
        break;
      }
    }

    if (erro) return erro;
  }

  // Validação dos campos da empresa
  const company = formData.company;

  if (company) {
    const companyFields = [
      { field: company.socialReason, message: "Razão social da empresa" },
      { field: company.fantasyName, message: "Nome fantasia da empresa" },
      { field: company.cnpj, message: "CNPJ da empresa" },
      { field: company.cepCompany, message: "CEP da empresa" },
      { field: company.address, message: "Endereço da empresa" },
      { field: company.cityCompany, message: "Cidade da empresa" },
      { field: company.state, message: "Estado da empresa" },
      { field: company.phone1, message: "Telefone 1 da empresa" },
      {
        field: company.financialResponsible,
        message: "Resp. financeiro da empresa",
      },
      { field: company.email, message: "E-mail da empresa" },
    ];

    for (let i = 0; i < companyFields.length; i++) {
      if (checkField(companyFields[i].field, companyFields[i].message)) {
        return erro;
      }
    }

    if (company.email.value && !isValidEmail(company.email.value)) {
      return "Digite um e-mail válido";
    }
  }

  return null;
}

export const validateAllGuestsExceptIndex2 = async (formData) => {
  if (formData.guests && Array.isArray(formData.guests)) {
    for (let i = 0; i < formData.guests.length; i++) {
      if (i === 2) continue;

      const guest = formData.guests[i];
      const error = await validation({ guests: [guest] });

      if (error) {
        return `Convidado ${i + 1}: ${error}`;
      }
    }
  }

  // Validação da empresa
  const companyError = await validation({ company: formData.company });
  if (companyError) {
    return companyError;
  }

  return null;
};
