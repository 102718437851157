import { Fragment, useEffect } from "react";
import { useApi } from "../../context/ApiContext";
import {
  SuperMarket101_200,
  SuperMarket10_30,
  SuperMarket200,
  SuperMarket31_100,
} from "../../components/forms/supermarket";

export default function Home() {
  const { apiData } = useApi();

  function getCompontByGroupId(groupId) {
    return (
      {
        66442: <SuperMarket10_30 data={apiData} />,
        66443: <SuperMarket31_100 data={apiData} />,
        66444: <SuperMarket101_200 data={apiData} />,
        66445: <SuperMarket200 data={apiData} />,
      }[groupId] || groupId
    );
  }

  return <Fragment>{getCompontByGroupId(apiData.GroupId)}</Fragment>;
}
