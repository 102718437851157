export function initialFormData(data) {
  // console.log("Initial data", data);
  return {
    guests: [],
    company: {
      socialReason: {
        value: data.Attributes[2]?.Value || "",
        id: 590713,
      },
      socialMedia: {
        value: "",
        id: 590715,
      },
      fantasyName: {
        value: data.Attributes[3]?.Value || "",
        id: 590716,
      },
      cnpj: {
        value: data.Attributes[0]?.Value || "",
        id: 590717,
      },
      stateRegistration: {
        value: "",
        id: 590718,
      },
      municipalRegistration: {
        value: "",
        id: 590719,
      },
      cepCompany: {
        value: "",
        id: 594224,
      },
      address: {
        value: "",
        id: 590720,
      },
      pais: {
        value: "",
        id: 597514,
      },
      cityCompany: {
        value: "",
        id: 590721,
      },
      state: {
        value: "",
        id: 590722,
      },
      phone1: {
        value: "",
        id: 590723,
      },
      phone2: {
        value: "",
        id: 590724,
      },
      financialResponsible: {
        value: "",
        id: 590726,
      },
      email: {
        value: "",
        id: 590728,
      },
    },
  };
}

export const quantityGuest = {
  name: {
    value: "",
    id: 578390,
  },
  badge: {
    value: "",
    id: 590675,
  },
  rg: {
    value: "",
    id: 590679,
  },
  cnpj: {
    value: "",
    id: 590717,
  },
  cpf: {
    value: "",
    id: 590677,
  },
  email: {
    value: "",
    id: 578391,
  },
  telephone: {
    value: "",
    id: 593869,
  },
  position: {
    value: "",
    id: 590678,
  },
  birth: {
    value: "",
    id: 590687,
  },
  gender: {
    value: "",
    id: 590688,
  },
  foodRestriction: {
    value: "",
    id: 594110,
  },
  whatRestriction: {
    value: "",
    id: 594111,
  },
  specificNeed: {
    value: "",
    id: 594113,
  },
  whatNeed: {
    value: "",
    id: 594114,
  },
  hasCompanion: {
    value: "",
    id: 590689,
  },
  needAir: {
    value: "",
    id: 590690,
  },
  upgrade: {
    value: "",
    id: 590707,
  },
  retailWomen: {
    value: "",
    id: 597513,
  },
  royalPalmPlaza: [
    {
      key: 1,
      id: 590708,
      label: "Early Check-in (quarto single) – R$ 550,00",
      value: 550,
    },
    {
      key: 2,
      id: 590708,
      label: "Early Check-in (quarto duplo) – R$ 650,00",
      value: 650,
    },
  ],
  alteracaoApto: [
    {
      key: 4,
      id: 590709,
      label:
        "Alteração de quarto single para duplo R$ 800,00 + ticket alimentação para a segunda pessoa1.º lote – R$ 1.300,00 // 2º lote – R$ 1.400,00 // 3º lote – R$ 1.500,00",
      value: 1300,
    },
  ],
  diariaExtra: [
    {
      key: 6,
      id: 590710,
      label: "Diária extra (quarto single) – R$ 1.100,00",
      value: 1100,
    },
    {
      key: 7,
      id: 590710,
      label: "Diária extra (quarto duplo) – R$ 1.200,00",
      value: 1200,
    },
    {
      key: 8,
      id: 590710,
      label: "Diária extra (quarto triplo) – R$ 1.400,00",
      value: 1400,
    },
  ],
  adicional_pack: [
    {
      key: 9,
      id: 598429,
      label:
        "Pacote com hospedagem (quarto single) – R$ 3.600,00 (valor para uma pessoa)",
      value: 3600,
    },
    {
      key: 10,
      id: 598429,
      label:
        "Pacote com hospedagem (quarto duplo) – R$ 5.000,00 (valor para duas pessoas)",
      value: 5000,
    },
    {
      key: 11,
      id: 598429,
      label:
        "Pacote com hospedagem (quarto triplo) – R$ 6.400,00 (valor para três pessoas)",
      value: 6400,
    },
    {
      key: 12,
      id: 598429,
      label: "Sem hospedagem – 1.º lote – R$ 1.300,00",
      value: 1300,
    },
  ],
  companion: {
    nameCompanion: {
      value: "",
      id: 578390,
    },
    badgeCompanion: {
      value: "",
      id: 590675,
    },
    rgCompanion: {
      value: "",
      id: 590679,
    },
    birthCompanion: {
      value: "",
      id: 590687,
    },
    genderCompanion: {
      value: "",
      id: 590688,
    },
    emailCompanion: {
      value: "",
      id: 578391,
    },
    cnpjCompanion: {
      value: "",
      id: 590717,
    },
    cpfCompanion: {
      value: "",
      id: 590677,
    },
    positionCompanion: {
      value: "",
      id: 590678,
    },
    foodRestrictionCompanion: {
      value: "",
      id: 594110,
    },
    whatRestrictionCompanion: {
      value: "",
      id: 594111,
    },
    specificNeedCompanion: {
      value: "",
      id: 594113,
    },
    whatNeedCompanion: {
      value: "",
      id: 594114,
    },
    kinship: {
      value: "",
      id: 596792,
    },
    retailWomenCompanion: {
      value: "",
      id: 597513,
    },
  },
  flight: {
    departureAirport: {
      value: "",
      id: 590691,
    }, // Aeroporto de partida
    departureCIA: {
      value: "",
      id: 590692,
    }, // CIA
    departureLocator: {
      value: "",
      id: 590693,
    }, // Localizador
    departureDate: {
      value: "",
      id: 590694,
    }, // Data de partida
    departureTime: {
      value: "",
      id: 590695,
    }, // Hora de partida
    arrivalAirport: {
      value: "",
      id: 590696,
    }, // Aeroporto de chegada
    arrivalDate: {
      value: "",
      id: 590697,
    }, // Data de chegada
    arrivalTime: {
      value: "",
      id: 594117,
    }, // Hora de chegada
    returnDepartureAirport: {
      value: "",
      id: 590700,
    }, // Aeroporto de partida
    returnCIA: {
      value: "",
      id: 590701,
    }, // CIA
    returnLocator: {
      value: "",
      id: 590702,
    }, // Localizador
    returnDepartureDate: {
      value: "",
      id: 590703,
    }, // Data de partida
    returnDepartureTime: {
      value: "",
      id: 590704,
    }, // Hora de partida
    returnArrivalAirport: {
      value: "",
      id: 590705,
    }, // Aeroporto de chegada
    returnArrivalDate: {
      value: "",
      id: 590706,
    }, // Data de chegada
    returnTime: {
      value: "",
      id: 594118,
    }, // Hora de chegada
  },
};

export const states = [
  { nome: "Acre", sigla: "AC" },
  { nome: "Alagoas", sigla: "AL" },
  { nome: "Amapá", sigla: "AP" },
  { nome: "Amazonas", sigla: "AM" },
  { nome: "Bahia", sigla: "BA" },
  { nome: "Ceará", sigla: "CE" },
  { nome: "Distrito Federal", sigla: "DF" },
  { nome: "Espírito Santo", sigla: "ES" },
  { nome: "Goiás", sigla: "GO" },
  { nome: "Maranhão", sigla: "MA" },
  { nome: "Mato Grosso", sigla: "MT" },
  { nome: "Mato Grosso do Sul", sigla: "MS" },
  { nome: "Minas Gerais", sigla: "MG" },
  { nome: "Pará", sigla: "PA" },
  { nome: "Paraíba", sigla: "PB" },
  { nome: "Paraná", sigla: "PR" },
  { nome: "Pernambuco", sigla: "PE" },
  { nome: "Piauí", sigla: "PI" },
  { nome: "Rio de Janeiro", sigla: "RJ" },
  { nome: "Rio Grande do Norte", sigla: "RN" },
  { nome: "Rio Grande do Sul", sigla: "RS" },
  { nome: "Rondônia", sigla: "RO" },
  { nome: "Roraima", sigla: "RR" },
  { nome: "Santa Catarina", sigla: "SC" },
  { nome: "São Paulo", sigla: "SP" },
  { nome: "Sergipe", sigla: "SE" },
  { nome: "Tocantins", sigla: "TO" },
];
