import React, { useState } from "react";
import "./styled.css";
// router
import { useNavigate } from "react-router-dom";
// img
import Banner from "../../assets/images/banner-lp-abras.webp";
import { validateCNPJ, maskCNPJ } from "../../utils/masks";
import { getDataLogin } from "../../utils/service/Login";
import { useApi } from "../../context/ApiContext";
import { useAuth } from "../../context/AuthenticationContext";

export default function Login() {
  const navigate = useNavigate();
  const { updateApiData } = useApi();
  const { login } = useAuth();

  const [cnpj, setCnpj] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!cnpj) {
      setError("Campo obrigatório");
      return;
    }

    if (!validateCNPJ(cnpj)) {
      setError("Digite um CNPJ válido");
      return;
    }

    try {
      const data = await getDataLogin(cnpj);

      updateApiData(data);
      login();
      navigate("/cadastro");
      setError("");
    } catch (error) {
      setError(
        error.message ||
          "Erro desconhecido. Tente novamente ou entre em contato!."
      );
    }
  };

  const handleChange = (e) => {
    setCnpj(maskCNPJ(e.target.value));
    setError("");
  };

  return (
    <section className="section-login">
      <div className="div-card-login">
        <img src={Banner} className="img-banner-login" alt="Banner de Login" />

        <form onSubmit={handleSubmit} style={{ padding: 15 }}>
          <label className="form-label">Digite seu CNPJ*</label>
          <input
            className="form-control"
            type="text"
            value={cnpj}
            onChange={handleChange}
          />
          {error && <p style={{ color: "red" }}>{error}</p>}

          <div className="div-btn">
            <button type="submit" className="a-btn-submit">
              Entrar
            </button>
          </div>
        </form>
      </div>
    </section>
  );
}
