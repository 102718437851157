export async function validation(formData) {
  // Função para validar campos obrigatórios
  const validateFields = (fields, entity) => {
    for (let i = 0; i < fields.length; i++) {
      const { value, label } = fields[i];
      if (!value) {
        return `${label} do ${entity}`;
      }
    }
    return null;
  };

  // Validação dos campos de convidados
  for (let i = 0; i < formData.guests.length; i++) {
    const guest = formData.guests[i];
    const fields = [
      { value: guest.name.value, label: `Nome do convidado` },
      { value: guest.badge.value, label: `Nome Crachá do convidado` },
      { value: guest.cpf.value, label: `CPF do convidado` },
      { value: guest.telephone.value, label: `Telefone do convidado` },
      {
        value: guest.birth.value,
        label: `Data de nascimento do convidado`,
      },
      { value: guest.gender.value, label: `Gênero do convidado` },
      { value: guest.email.value, label: `E-mail do convidado` },
      { value: guest.retailWomen.value, label: `Mulheres do varejo` },
    ];

    const erro = validateFields(fields, "convidado");
    if (erro) return erro;
  }

  // Validação dos campos da empresa
  const company = formData.company;
  const companyFields = [
    { value: company.socialReason.value, label: "Razão social" },
    { value: company.fantasyName.value, label: "Nome fantasia" },
    { value: company.cepCompany.value, label: `CEP da empresa` },
    { value: company.address.value, label: "Endereço" },
    { value: company.state.value, label: "Estado da empresa" },
    { value: company.phone1.value, label: "Telefone 1 da empresa" },
    { value: company.financialResponsible.value, label: "Resp. financeiro" },
    { value: company.email.value, label: "E-mail" },
  ];

  return validateFields(companyFields, "empresa");
}
