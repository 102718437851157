import i18next from "i18next";
import React from "react";
import ReactDOM from "react-dom/client";
import { I18nextProvider, initReactI18next } from "react-i18next";

import App from "./App";
import global_pt from "./translations/pt/global.json";
import { ApiProvider } from "./context/ApiContext";
import { AuthProvider } from "./context/AuthenticationContext";

i18next.use(initReactI18next).init({
  interpolation: { escapeValue: false },
  lng: "pt",
  resources: {
    pt: {
      translation: global_pt,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthProvider>
    <ApiProvider>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </ApiProvider>
  </AuthProvider>
);
