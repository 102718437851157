import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader, Row, Col } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import { formatCep, formatCpf } from "../../utils/masks";
import { fetchAddressByCEP } from "../../utils/service/cepService";

export default function ModalNewCompanion(props) {
  const { isOpen, toggle, onSubmit, companionData } = props;
  const [companion, setCompanion] = useState(companionData);
  const [warningAlert, setWarningAlert] = useState("");
  const [successAlert, setSuccessAlert] = useState(false);

  const fetchAddress = async (cep) => {
    try {
      const cleanCep = cep.replace(/\D/g, "");

      const address = await fetchAddressByCEP(cleanCep);

      setCompanion((prevData) => ({
        ...prevData,
        addressCompanion: {
          value: address.logradouro || "Não encontrado!",
          id: prevData.addressCompanion?.id ?? 0,
        },
        bairroCompanion: {
          value: address.bairro || "Não encontrado!",
          id: prevData.bairroCompanion?.id ?? 0,
        },
        cityCompanion: {
          value: address.localidade || "Não encontrado!",
          id: prevData.cityCompanion?.id ?? 0,
        },
        stateCompanion: {
          value: address.uf || "Não encontrado!",
          id: prevData.stateCompanion?.id ?? 0,
        },
      }));
    } catch (error) {
      // console.error("Erro ao buscar endereço:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setCompanion((prevData) => {
      const updatedData = {
        ...prevData,
        [name]: { value: value, id: prevData[name]?.id ?? 0 },
      };

      if (name === "cepCompanion") {
        updatedData.addressCompanion = { value: "", id: 590680 };
        updatedData.bairroCompanion = { value: "", id: 590681 };
        updatedData.cityCompanion = { value: "", id: 590684 };
        updatedData.stateCompanion = { value: "", id: 590685 };

        fetchAddress(value);
      }

      return updatedData;
    });
  };

  const verifyErrors = () => {
    if (!companion.nameCompanion.value) {
      return "O campo Nome é obrigatório";
    } else if (!companion.cpfCompanion.value) {
      return "O campo CPF é obrigatório";
    } else if (!companion.emailCompanion.value) {
      return "O campo Email é obrigatório";
    } else if (!companion.positionCompanion.value) {
      return "O campo Cargo é obrigatório";
    }
    return null;
  };

  const submitForm = (e) => {
    e.preventDefault();
    const error = verifyErrors();
    if (error) {
      setWarningAlert(error);
      return;
    }
    setSuccessAlert(true);
    onSubmit(companion);
  };

  // useEffect(() => {
  //   console.log({ companion });
  // }, [companion]);

  return (
    <>
      {warningAlert && (
        <SweetAlert
          title="Alerta"
          warning
          onConfirm={() => setWarningAlert("")}
        >
          {warningAlert}
        </SweetAlert>
      )}

      {successAlert && (
        <SweetAlert
          title="Sucesso"
          success
          onConfirm={() => {
            toggle();
            setSuccessAlert(false);
          }}
        >
          Dados do acompanhante salvos com sucesso!
        </SweetAlert>
      )}

      <Modal isOpen={isOpen} scrollable style={{ borderRadius: 0 }}>
        <ModalHeader toggle={() => toggle()}>
          Informações do acompanhante
        </ModalHeader>
        <ModalBody>
          <form onSubmit={submitForm}>
            <Row>
              <Col md={12} style={{ marginTop: 10 }}>
                <label className="form-label">Nome*</label>
                <input
                  className="form-control"
                  type="text"
                  name="nameCompanion"
                  value={companion.nameCompanion.value}
                  onChange={handleChange}
                  placeholder="Ex: João"
                />
              </Col>
            </Row>

            <Row>
              <Col md={6} style={{ marginTop: 10 }}>
                <label className="form-label">Nome Crachá*</label>
                <input
                  className="form-control"
                  type="text"
                  name="badgeCompanion"
                  value={companion.badgeCompanion.value}
                  onChange={handleChange}
                  placeholder="Ex: João123"
                  maxLength={20}
                />
              </Col>

              <Col md={6} style={{ marginTop: 10 }}>
                <label className="form-label">E-mail*</label>
                <input
                  className="form-control"
                  type="text"
                  name="emailCompanion"
                  value={companion.emailCompanion.value}
                  onChange={handleChange}
                  placeholder="example@example.com"
                />
              </Col>
            </Row>

            <Row>
              <Col md={6} style={{ marginTop: 10 }}>
                <label className="form-label">CPF*</label>
                <input
                  className="form-control"
                  type="text"
                  name="cpfCompanion"
                  value={formatCpf(companion.cpfCompanion.value)}
                  placeholder="123.456.789.00"
                  onChange={handleChange}
                  maxLength={14}
                />
              </Col>

              <Col md={6} style={{ marginTop: 10 }}>
                <label className="form-label">RG*</label>
                <input
                  className="form-control"
                  type="text"
                  name="rgCompanion"
                  value={companion.rgCompanion.value}
                  onChange={handleChange}
                />
              </Col>
            </Row>

            <Row>
              <Col md={6} style={{ marginTop: 10 }}>
                <label className="form-label">Data de nascimento*</label>
                <input
                  className="form-control"
                  type="date"
                  name="birthCompanion"
                  value={companion.birthCompanion.value}
                  onChange={handleChange}
                />
              </Col>

              <Col md={6} style={{ marginTop: 10 }}>
                <label className="form-label">Cargo*</label>
                <input
                  className="form-control"
                  type="text"
                  name="positionCompanion"
                  value={companion.positionCompanion.value}
                  onChange={handleChange}
                  placeholder="Ex: Gerente"
                />
              </Col>
            </Row>

            {/* <Row>
              <Col md={6} style={{ marginTop: 10 }}>
                <label className="form-label">CEP*</label>
                <input
                  className="form-control"
                  type="text"
                  name="cepCompanion"
                  value={formatCep(companion.cepCompanion.value)}
                  onChange={handleChange}
                  placeholder="Ex: 12345-678"
                />
              </Col>

              <Col md={6} style={{ marginTop: 10 }}>
                <label className="form-label">Endereço*</label>
                <input
                  className="form-control"
                  type="text"
                  name="addressCompanion"
                  value={companion.addressCompanion.value}
                  onChange={handleChange}
                  placeholder="Ex: Rua A, 123"
                />
              </Col>
            </Row> */}

            {/* <Row>
              <Col md={6} style={{ marginTop: 10 }}>
                <label className="form-label">Bairro*</label>
                <input
                  className="form-control"
                  type="text"
                  name="bairroCompanion"
                  value={companion.bairroCompanion.value}
                  onChange={handleChange}
                  placeholder="Ex: Centro"
                />
              </Col>

              <Col md={6} style={{ marginTop: 10 }}>
                <label className="form-label">Número*</label>
                <input
                  className="form-control"
                  type="text"
                  name="numberCompanion"
                  value={companion.numberCompanion.value}
                  onChange={handleChange}
                  placeholder="Ex: 432"
                />
              </Col>
            </Row> */}

            {/* <Row>
              <Col md={6} style={{ marginTop: 10 }}>
                <label className="form-label">Cidade*</label>
                <input
                  className="form-control"
                  type="text"
                  name="cityCompanion"
                  value={companion.cityCompanion.value}
                  onChange={handleChange}
                  placeholder="Ex: São Paulo"
                />
              </Col>

              <Col md={6} style={{ marginTop: 10 }}>
                <label className="form-label">Estado*</label>
                <input
                  className="form-control"
                  type="text"
                  name="stateCompanion"
                  value={companion.stateCompanion.value}
                  onChange={handleChange}
                  placeholder="Ex: SP"
                />
              </Col>
            </Row> */}

            <Row>
              {/* <Col md={6} style={{ marginTop: 10 }}>
                <label className="form-label">Complemento (opcional)</label>
                <input
                  className="form-control"
                  type="text"
                  name="complementCompanion"
                  value={companion.complementCompanion.value}
                  onChange={handleChange}
                  placeholder="Ex: Apartamento, bloco, etc."
                />
              </Col> */}

              <Col md={6} style={{ marginTop: 10 }}>
                <label className="form-label">Gênero*</label>

                <select
                  className="form-control"
                  name="genderCompanion"
                  value={companion.genderCompanion.value}
                  onChange={handleChange}
                >
                  <option disabled value="">
                    Selecione
                  </option>
                  <option value="Masculino">Masculino</option>
                  <option value="Feminino">Feminino</option>
                </select>
              </Col>
            </Row>

            <Col md={12} style={{ marginTop: 10 }}>
              <label className="form-label">
                Você possui alguma restrição alimentar?*
              </label>

              <select
                className="form-control"
                name="foodRestrictionCompanion"
                value={companion.foodRestrictionCompanion.value}
                onChange={handleChange}
              >
                <option disabled value="">
                  Selecione
                </option>
                <option value="sim">Sim</option>
                <option value="não">Não</option>
              </select>

              {companion.foodRestrictionCompanion.value === "sim" && (
                <Col md={12} style={{ marginTop: 10 }}>
                  <label className="form-label">Qual?*</label>
                  <input
                    className="form-control"
                    type="text"
                    name="whatRestrictionCompanion"
                    value={companion.whatRestrictionCompanion.value}
                    onChange={handleChange}
                    placeholder="Ex: lactose"
                  />
                </Col>
              )}
            </Col>

            <Col md={12} style={{ marginTop: 10 }}>
              <label className="form-label">
                Você possui alguma necessidade específica?*
              </label>

              <select
                className="form-control"
                name="specificNeedCompanion"
                value={companion.specificNeedCompanion.value}
                onChange={handleChange}
              >
                <option disabled value="">
                  Selecione
                </option>
                <option value="sim">Sim</option>
                <option value="não">Não</option>
              </select>

              {companion.specificNeedCompanion.value === "sim" && (
                <Col md={12} style={{ marginTop: 10 }}>
                  <label className="form-label">Qual?*</label>
                  <input
                    className="form-control"
                    type="text"
                    name="whatNeedCompanion"
                    value={companion.whatNeedCompanion.value}
                    onChange={handleChange}
                    placeholder="Ex: lactose"
                  />
                </Col>
              )}
            </Col>

            <Col md={12} style={{ marginTop: 10 }}>
              <label className="form-label">Grau de parentesco*</label>
              <input
                className="form-control"
                type="text"
                name="kinship"
                value={companion.kinship.value}
                onChange={handleChange}
              />
            </Col>

            <Col md={12} style={{ marginTop: 10 }}>
              <label className="form-label">Mulheres do varejo?*</label>

              <select
                className="form-control"
                name="retailWomenCompanion"
                value={companion.retailWomenCompanion.value || ""}
                onChange={handleChange}
              >
                <option disabled value="">
                  Selecione
                </option>
                <option value="sim">Sim</option>
                <option value="não">Não</option>
              </select>
            </Col>

            <div
              className="d-flex justify-content-end"
              style={{ marginTop: 20 }}
            >
              <button type="submit" className="btn btn-primary">
                Salvar
              </button>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
}
