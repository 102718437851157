import React from "react";
import "./styles.css";
// img
import Banner from "../../assets/images/banner-lp-abras.webp";
import { useAuth } from "../../context/AuthenticationContext";
export default function Confirmation() {
  const { logout } = useAuth();

  return (
    <section className="section-login">
      <div className="div-card-login">
        <img src={Banner} className="img-banner-login" alt="Banner de Login" />

        <article
          style={{
            padding: 15,
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <h4 style={{ textAlign: "center" }}>
            A inscrição foi realizada com sucesso! <br /> Verifique o e-mail e a
            caixa de spam para mais informações.
          </h4>

          <div className="div-btn">
            <button onClick={logout} className="a-btn-submit">
              Voltar para o inicio
            </button>
          </div>
        </article>
      </div>
    </section>
  );
}
