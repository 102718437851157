import sendEmail from "../../../../utils/service/Email/sendEmail";
import {
  getTemplateConfirmation,
  getTemplatePending,
} from "../../../../utils/service/Email/templateEmail";
import { sendToMice } from "../../../../utils/service/mice";

export default async function dataMice(data, apiData) {
  try {
    for (const guest of data.guests) {
      const confirmation = guest.upgrade.value === "sim" ? "NE" : "CN";

      await processGuest(guest, data.company, confirmation, apiData);
    }

    console.log("All guests processed successfully");
    return { data: "enviado" };
  } catch (error) {
    console.error("Error in dataMice:", error);
    return { data: "erro ao enviar", erro: error };
  }
}

function getSelectedUpgrades(options) {
  if (!Array.isArray(options)) return [];

  return options
    .filter((option) => option.selected)
    .map((option) => ({
      id: option.id,
      value: `${option.label} - ${option.hotel}`,
    }));
}

async function processGuest(guest, company, confirmation, apiData) {
  const guestData = mapGuestData(guest);
  const companyData = mapCompanyData(company);

  const selectedUpgrades = [
    ...getSelectedUpgrades(guest.alteracaoApto || []),
    ...getSelectedUpgrades(guest.tower_anhanguera || []),
  ];

  const completeData = [
    ...guestData,
    ...companyData,
    ...selectedUpgrades,
    {
      id: 593872,
      value: apiData.Attributes[4]?.Value,
    },
    {
      id: 593873,
      value: apiData.Attributes[5]?.Value,
    },
    {
      id: 596940,
      value: apiData.Attributes[9]?.Value || "",
    },
  ];

  try {
    // console.log("complete Data (success)", { completeData });
    await sendToMice(completeData, 66499, 139337, confirmation);

    const templateMail =
      confirmation === "NE"
        ? getTemplatePending(
            guest.name.value,
            guest.companion.nameCompanion.value || "Não levará acompanhante"
          )
        : getTemplateConfirmation(
            guest.name.value,
            guest.companion.nameCompanion.value || "Não levará acompanhante"
          );

    await sendEmail(
      guest.email.value,
      `Inscrição ABRAS 2024 - ${
        confirmation === "NE" ? "Pendente" : "Confirmada"
      }`,
      templateMail
    );
    console.log("Email sent to:", guest.email.value);
  } catch (error) {
    console.error("Error in processGuest:", error);
    return error;
  }
}

function mapGuestData(guest) {
  return [
    { id: guest.name.id, value: guest.name.value },
    { id: guest.badge.id, value: guest.badge.value },
    { id: guest.position.id, value: guest.position.value },
    { id: guest.cpf.id, value: guest.cpf.value },
    { id: guest.rg.id, value: guest.rg.value },
    { id: guest.telephone.id, value: guest.telephone.value },
    { id: guest.birth.id, value: guest.birth.value },
    { id: guest.gender.id, value: guest.gender.value },
    { id: guest.email.id, value: guest.email.value },
    { id: guest.foodRestriction.id, value: guest.foodRestriction.value },
    { id: guest.whatRestriction.id, value: guest.whatRestriction.value },
    { id: guest.specificNeed.id, value: guest.specificNeed.value },
    { id: guest.whatNeed.id, value: guest.whatNeed.value },
    { id: guest.hasCompanion.id, value: guest.hasCompanion.value },
    { id: guest.needAir.id, value: guest.needAir.value },
    { id: guest.upgrade.id, value: guest.upgrade.value },
    {
      id: guest.retailWomen.id,
      value: guest.retailWomen.value,
    },
  ];
}

function mapCompanyData(company) {
  return [
    { id: company.socialReason.id, value: company.socialReason.value },
    { id: company.socialMedia.id, value: company.socialMedia.value },
    { id: company.fantasyName.id, value: company.fantasyName.value },
    { id: company.cnpj.id, value: company.cnpj.value },
    {
      id: company.stateRegistration.id,
      value: company.stateRegistration.value,
    },
    {
      id: company.municipalRegistration.id,
      value: company.municipalRegistration.value,
    },
    { id: company.cepCompany.id, value: company.cepCompany.value },
    { id: company.cityCompany.id, value: company.cityCompany.value },
    { id: company.address.id, value: company.address.value },
    { id: company.state.id, value: company.state.value },
    { id: company.pais.id, value: company.pais.value },
    { id: company.phone1.id, value: company.phone1.value },
    { id: company.phone2.id, value: company.phone2.value },
    {
      id: company.financialResponsible.id,
      value: company.financialResponsible.value,
    },
    { id: company.email.id, value: company.email.value },
  ];
}
