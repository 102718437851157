import React, { useEffect, useState } from "react";
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  UncontrolledAccordion,
  Row,
  Col,
} from "reactstrap";
import "../styles/styles.css";
// alerta
import SweetAlert from "react-bootstrap-sweetalert";
import Banner from "../../../../assets/images/banner-lp-abras.webp";

import { validation } from "../config/validation";
// icones
import { IoPersonAdd } from "react-icons/io5";
import { FaPlaneDeparture } from "react-icons/fa";
import { FaUserCheck } from "react-icons/fa";
// formatar dado para o mice
import dataMice from "../config/dataMice";
//services
import {
  formatCep,
  formatCpf,
  formatPhoneNumber,
  maskCNPJ,
} from "../../../../utils/masks";
import { fetchAddressByCEP } from "../../../../utils/service/cepService";
import { initialFormData, quantityGuest } from "../config/initialState";
import { useApi } from "../../../../context/ApiContext";
import {
  handleCompanionDataSubmit,
  handleFlightDataSubmit,
} from "../config/handleModal";
import ModalNewAir from "../../../modals/ModalAir";
import ModalNewCompanion from "../../../modals/ModalNewCompanion";
import Confirmation from "../../../confirmation";

export default function SuperMarket31_100({ data }) {
  // modais
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenAir, setIsOpenAir] = useState(false);
  // alerta
  const [warningAlert, setWarningAlert] = useState("");
  const [successAlert, setSuccessAlert] = useState(false);
  // dados do usuário
  const [selectedGuestIndex, setSelectedGuestIndex] = useState(null);
  const [formData, setFormData] = useState(() => ({
    ...initialFormData(data),
    guests: Array.from({ length: 2 }, () => ({ ...quantityGuest })),
  }));
  // carregar
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);

  const { apiData } = useApi();

  const toggle = () => setIsOpen(!isOpen);
  const toggleAir = () => setIsOpenAir(!isOpenAir);

  const fetchAddress = async (cep, index) => {
    try {
      const address = await fetchAddressByCEP(cep);

      setFormData((prevData) => {
        const newGuests = [...prevData.guests];
        const guest = newGuests[index];

        newGuests[index] = {
          ...guest,
          address: {
            value: address.logradouro || "Não encontrado!",
            id: guest.address?.id ?? 0,
          },
          bairro: {
            value: address.bairro || "Não encontrado!",
            id: guest.bairro?.id ?? 0,
          },
          city: {
            value: address.localidade || "Não encontrado!",
            id: guest.city?.id ?? 0,
          },
          state: {
            value: address.uf || "Não encontrado!",
            id: guest.state?.id ?? 0,
          },
        };

        return { ...prevData, guests: newGuests };
      });
    } catch (error) {
      // console.error("Erro ao buscar endereço:", error);
    }
  };

  const handleCheckboxChange = (index, category, id) => {
    setFormData((prevData) => {
      const newGuests = [...prevData.guests];
      newGuests[index][category] = newGuests[index][category].map((item) => ({
        ...item,
        selected: item.key === id ? !item.selected : false,
      }));

      return { ...prevData, guests: newGuests };
    });
  };

  const handleGuestChange = (index, e) => {
    const { name, value } = e.target;

    setFormData((prevData) => {
      const newGuests = [...prevData.guests];

      newGuests[index] = {
        ...newGuests[index],
        [name]: {
          value: value,
          id: newGuests[index][name]?.id ?? 0,
        },
      };

      // Reset address fields if cep changes
      if (name === "cep") {
        newGuests[index].address = { value: "", id: 590680 };
        newGuests[index].bairro = { value: "", id: 590681 };
        newGuests[index].city = { value: "", id: 590684 };
        newGuests[index].state = { value: "", id: 590685 };

        fetchAddress(value, index);
      }

      // Reset specific categories if upgrade is "não"
      if (name === "upgrade" && value === "não") {
        [
          "royalPalmPlaza",
          "alteracaoApto",
          "diariaExtra",
          "adicional_pack",
        ].forEach((category) => {
          newGuests[index][category] = newGuests[index][category].map(
            (item) => ({
              ...item,
              selected: false,
            })
          );
        });
      }

      if (name === "foodRestriction" && value === "não") {
        newGuests[index].whatRestriction = {
          value: "",
          id: newGuests[index].whatRestriction?.id ?? 0,
        };
      }

      if (name === "specificNeed" && value === "não") {
        newGuests[index].whatNeed = {
          value: "",
          id: newGuests[index].whatNeed?.id ?? 0,
        };
      }

      return { ...prevData, guests: newGuests };
    });
  };

  const fetchAddressCompany = async (cep) => {
    try {
      const address = await fetchAddressByCEP(cep);

      setFormData((prevData) => ({
        ...prevData,
        company: {
          ...prevData.company,
          address: {
            value: address.logradouro || "Não encontrado!",
            id: prevData.company.address?.id ?? 0,
          },
          cityCompany: {
            value: address.localidade || "Não encontrado!",
            id: prevData.company.cityCompany?.id ?? 0,
          },
          pais: {
            value: "Brazil",
            id: prevData.company.pais?.id ?? 0,
          },
          state: {
            value: address.uf || "Não encontrado!",
            id: prevData.company.state?.id ?? 0,
          },
        },
      }));
    } catch (error) {
      // console.error("Erro ao buscar endereço:", error);
    }
  };

  const handleCompanyChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => {
      const newCompany = {
        ...prevFormData.company,
        [name]: {
          value: value,
          id: prevFormData.company[name]?.id ?? 0,
        },
      };

      if (name === "cepCompany") {
        newCompany.address = { value: "", id: 590720 };
        newCompany.cityCompany = { value: "", id: 590721 };
        newCompany.state = { value: "", id: 590722 };
        newCompany.pais = { value: "", id: 597514 };

        fetchAddressCompany(value);
      }

      return { ...prevFormData, company: newCompany };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    var haveError = await validation(formData);

    if (haveError) {
      setWarningAlert(`O campo ${haveError} é obrigatório`);
      setLoading(false);
      return;
    }

    await dataMice(formData, 66497, data).then((res) => {
      if (res.data === "enviado") {
        setLoading(false);
        setSuccessAlert(true);
        setFormData(() => ({
          ...initialFormData(data),
          guests: Array.from({ length: 2 }, () => ({ ...quantityGuest })),
        }));
        setSent(true);
      }
    });
    // .catch((error) => console.log("erro", error));
  };

  if (sent) return <Confirmation />;

  return (
    <>
      {warningAlert && (
        <SweetAlert
          title="Alerta"
          warning
          onConfirm={() => setWarningAlert("")}
        >
          {warningAlert}
        </SweetAlert>
      )}

      {successAlert && (
        <SweetAlert
          title="Enviado"
          success
          onConfirm={() => setSuccessAlert(false)}
        >
          Seus dados foram salvos com sucesso!
        </SweetAlert>
      )}

      {isOpen && selectedGuestIndex !== null && (
        <ModalNewCompanion
          isOpen={isOpen}
          toggle={toggle}
          companionData={formData.guests[selectedGuestIndex]?.companion}
          onSubmit={(companionData) =>
            handleCompanionDataSubmit(
              companionData,
              setFormData,
              selectedGuestIndex
            )
          }
        />
      )}

      {isOpenAir && selectedGuestIndex !== null && (
        <ModalNewAir
          isOpen={isOpenAir}
          toggle={toggleAir}
          flightData={formData.guests[selectedGuestIndex]?.flight}
          onSubmit={(flightData) =>
            handleFlightDataSubmit(flightData, setFormData, selectedGuestIndex)
          }
        />
      )}

      <section className="section-home">
        <form onSubmit={handleSubmit} className="div-card-home">
          <img src={Banner} className="img-banner-home" />
          <div style={{ padding: 15 }}>
            <p style={{ display: "flex", flexDirection: "column" }}>
              <a>
                <span style={{ fontWeight: "bold" }}>Perfil:</span>{" "}
                {data.Attributes[8]?.Value}
              </a>
              <a>
                <span style={{ fontWeight: "bold" }}>Itens disponíveis:</span>{" "}
                {data.Attributes[6]?.Value}
              </a>
            </p>

            <p style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ fontWeight: "bold" }}>
                Informações sobre o hotel.
              </span>
              <span style={{ fontWeight: "bold" }}>
                Hotel: {data.Attributes[5]?.Value}
              </span>
              <a>{data.Attributes[7]?.Value}</a>
            </p>
          </div>

          <UncontrolledAccordion defaultOpen={1}>
            {formData.guests.map((guest, index) => (
              <AccordionItem>
                <AccordionHeader targetId={index + 1}>
                  <h4>Dados do convidado {index + 1}</h4>
                </AccordionHeader>
                <AccordionBody accordionId={index + 1}>
                  <div key={index}>
                    <Row>
                      <Col md={6} style={{ marginTop: 10 }}>
                        <label className="form-label">CPF*</label>
                        <input
                          className="form-control"
                          type="text"
                          name="cpf"
                          value={formatCpf(guest.cpf.value || "")}
                          onChange={(e) => handleGuestChange(index, e)}
                          placeholder="123.456.789.00"
                          maxLength={14}
                        />
                      </Col>

                      <Col md={6} style={{ marginTop: 10 }}>
                        <label className="form-label">RG*</label>
                        <input
                          className="form-control"
                          type="text"
                          name="rg"
                          value={guest.rg.value || ""}
                          onChange={(e) => handleGuestChange(index, e)}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col md={12} style={{ marginTop: 10 }}>
                        <label className="form-label">Nome completo*</label>
                        <input
                          className="form-control"
                          type="text"
                          name="name"
                          value={guest.name.value || ""}
                          onChange={(e) => handleGuestChange(index, e)}
                          placeholder="Ex: Fulano de Oliveira"
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6} style={{ marginTop: 10 }}>
                        <label className="form-label">Nome Crachá*</label>
                        <input
                          className="form-control"
                          type="text"
                          name="badge"
                          value={guest.badge.value || ""}
                          onChange={(e) => handleGuestChange(index, e)}
                          placeholder="Ex: João123"
                          maxLength={20}
                        />
                      </Col>

                      <Col md={6} style={{ marginTop: 10 }}>
                        <label className="form-label">E-mail*</label>
                        <input
                          className="form-control"
                          type="text"
                          name="email"
                          value={guest.email.value || ""}
                          onChange={(e) => handleGuestChange(index, e)}
                          placeholder="example@example.com"
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6} style={{ marginTop: 10 }}>
                        <label className="form-label">Telefone*</label>
                        <input
                          className="form-control"
                          type="text"
                          name="telephone"
                          value={formatPhoneNumber(guest.telephone.value || "")}
                          onChange={(e) => handleGuestChange(index, e)}
                          placeholder="Ex: (11) 12345-6789"
                        />
                      </Col>

                      <Col md={6} style={{ marginTop: 10 }}>
                        <label className="form-label">Sexo*</label>
                        <select
                          className="form-control"
                          name="gender"
                          value={guest.gender.value || ""}
                          onChange={(e) => handleGuestChange(index, e)}
                        >
                          <option disabled value="">
                            Selecione
                          </option>
                          <option value="Masculino">Masculino</option>
                          <option value="Feminino">Feminino</option>
                        </select>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6} style={{ marginTop: 10 }}>
                        <label className="form-label">
                          Data de nascimento*
                        </label>
                        <input
                          className="form-control"
                          type="date"
                          name="birth"
                          value={guest.birth.value || ""}
                          onChange={(e) => handleGuestChange(index, e)}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col md={12} style={{ marginTop: 10 }}>
                        <label className="form-label">
                          Você possui alguma restrição alimentar?*
                        </label>
                        <select
                          className="form-control"
                          name="foodRestriction"
                          value={guest.foodRestriction.value || ""}
                          onChange={(e) => handleGuestChange(index, e)}
                        >
                          <option disabled value="">
                            Selecione
                          </option>
                          <option value="sim">Sim</option>
                          <option value="não">Não</option>
                        </select>

                        {guest.foodRestriction.value === "sim" && (
                          <Col md={12} style={{ marginTop: 10 }}>
                            <label className="form-label">Qual?*</label>
                            <input
                              className="form-control"
                              type="text"
                              name="whatRestriction"
                              value={guest.whatRestriction.value || ""}
                              onChange={(e) => handleGuestChange(index, e)}
                            />
                          </Col>
                        )}
                      </Col>

                      <Col md={12} style={{ marginTop: 10 }}>
                        <label className="form-label">
                          Você possui alguma necessidade específica?*
                        </label>
                        <select
                          className="form-control"
                          name="specificNeed"
                          value={guest.specificNeed.value || ""}
                          onChange={(e) => handleGuestChange(index, e)}
                        >
                          <option disabled value="">
                            Selecione
                          </option>
                          <option value="sim">Sim</option>
                          <option value="não">Não</option>
                        </select>

                        {guest.specificNeed.value === "sim" && (
                          <Col md={12} style={{ marginTop: 10 }}>
                            <label className="form-label">Qual?*</label>
                            <input
                              className="form-control"
                              type="text"
                              name="whatNeed"
                              value={guest.whatNeed.value || ""}
                              onChange={(e) => handleGuestChange(index, e)}
                              placeholder="Ex: Dificuldade com locomoção"
                            />
                          </Col>
                        )}
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6} style={{ marginTop: 10 }}>
                        <label className="form-label">
                          Deseja inserir acompanhante?*
                        </label>
                        <select
                          className="form-control"
                          name="hasCompanion"
                          value={guest.hasCompanion.value || ""}
                          onChange={(e) => handleGuestChange(index, e)}
                        >
                          <option disabled value="">
                            Selecione
                          </option>
                          <option value="sim">Sim</option>
                          <option value="não">Não</option>
                        </select>

                        {guest.hasCompanion.value === "sim" && (
                          <div className="div-btn-companion">
                            <div
                              onClick={() => {
                                setSelectedGuestIndex(index);
                                toggle();
                              }}
                              className="btn-companion"
                              style={{
                                backgroundColor:
                                  guest.companion.nameCompanion.value &&
                                  guest.companion.nameCompanion.value != ""
                                    ? "#52d146"
                                    : "#6262f6",
                              }}
                            >
                              {guest.companion.nameCompanion.value &&
                              guest.companion.nameCompanion.value != "" ? (
                                <FaUserCheck size={25} />
                              ) : (
                                <IoPersonAdd size={25} />
                              )}
                              Dados do acompanhante
                            </div>
                          </div>
                        )}
                      </Col>

                      <Col md={6} style={{ marginTop: 10 }}>
                        <label className="form-label">
                          Você já emitiu o seu aéreo para ir ao evento?
                        </label>
                        <select
                          className="form-control"
                          name="needAir"
                          value={guest.needAir.value || ""}
                          onChange={(e) => handleGuestChange(index, e)}
                        >
                          <option disabled value="">
                            Selecione
                          </option>
                          <option value="sim">Sim</option>
                          <option value="não">Não</option>
                        </select>

                        {guest.needAir.value === "sim" && (
                          <div className="div-btn-companion">
                            <div
                              onClick={() => {
                                setSelectedGuestIndex(index);
                                toggleAir();
                              }}
                              className="btn-companion"
                              style={{
                                backgroundColor:
                                  guest.flight.departureAirport.value &&
                                  guest.flight.departureAirport.value != ""
                                    ? "#52d146"
                                    : "#6262f6",
                              }}
                            >
                              <FaPlaneDeparture size={25} />
                              Adicionar voo
                            </div>
                          </div>
                        )}
                      </Col>
                    </Row>

                    <Row style={{ marginTop: 10 }}>
                      <Col md={12}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                          }}
                        >
                          <label className="form-label">
                            Você gostaria de adicionar itens extras em seu
                            pacote?
                          </label>
                          <select
                            className="form-control"
                            name="upgrade"
                            value={guest.upgrade.value || ""}
                            onChange={(e) => handleGuestChange(index, e)}
                          >
                            <option disabled value="">
                              Selecione
                            </option>
                            <option value="sim">Sim</option>
                            <option value="não">Não</option>
                          </select>

                          {guest.upgrade.value === "sim" && (
                            <>
                              <aside>
                                <strong>
                                  EARLY CHECK-IN - ROYAL PALM PLAZA
                                </strong>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "5px",
                                  }}
                                >
                                  {guest.royalPalmPlaza.map((item, i) => (
                                    <div
                                      className="div-checkbox"
                                      key={item.key}
                                    >
                                      <input
                                        style={{
                                          width: "20px",
                                          height: "20px",
                                        }}
                                        type="checkbox"
                                        checked={item.selected || false}
                                        onChange={() =>
                                          handleCheckboxChange(
                                            index,
                                            "royalPalmPlaza",
                                            item.key
                                          )
                                        }
                                      />
                                      <p
                                        style={{
                                          marginTop: "auto",
                                          marginBottom: "auto",
                                        }}
                                      >
                                        {item.label}
                                      </p>
                                    </div>
                                  ))}
                                </div>
                              </aside>

                              <aside>
                                <strong>
                                  ALTERAÇÃO DE APTO - ROYAL PALM PLAZA
                                </strong>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "5px",
                                  }}
                                >
                                  {guest.alteracaoApto.map((item, i) => (
                                    <div
                                      className="div-checkbox"
                                      key={item.key}
                                    >
                                      <div
                                        style={{
                                          width: "20px",
                                          height: "20px",
                                        }}
                                      >
                                        <input
                                          style={{
                                            width: "20px",
                                            height: "20px",
                                          }}
                                          type="checkbox"
                                          checked={item.selected || false}
                                          onChange={() =>
                                            handleCheckboxChange(
                                              index,
                                              "alteracaoApto",
                                              item.key
                                            )
                                          }
                                        />
                                      </div>
                                      <p
                                        style={{
                                          marginTop: "auto",
                                          marginBottom: "auto",
                                        }}
                                      >
                                        {item.label}
                                      </p>
                                    </div>
                                  ))}
                                </div>
                              </aside>

                              <aside>
                                <strong>DIÁRIA EXTRA - ROYAL PALM PLAZA</strong>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "5px",
                                  }}
                                >
                                  {guest.diariaExtra.map((item, i) => (
                                    <div
                                      className="div-checkbox"
                                      key={item.key}
                                    >
                                      <input
                                        style={{
                                          width: "20px",
                                          height: "20px",
                                        }}
                                        type="checkbox"
                                        checked={item.selected || false}
                                        onChange={() =>
                                          handleCheckboxChange(
                                            index,
                                            "diariaExtra",
                                            item.key
                                          )
                                        }
                                      />
                                      <p
                                        style={{
                                          marginTop: "auto",
                                          marginBottom: "auto",
                                        }}
                                      >
                                        {item.label}
                                      </p>
                                    </div>
                                  ))}
                                </div>
                              </aside>

                              <aside>
                                <strong>
                                  PACOTE ADICIONAL - ROYAL PALM PLAZA
                                </strong>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "5px",
                                  }}
                                >
                                  {guest.adicional_pack.map((item, i) => (
                                    <div
                                      className="div-checkbox"
                                      key={item.key}
                                    >
                                      <input
                                        style={{
                                          width: "20px",
                                          height: "20px",
                                        }}
                                        type="checkbox"
                                        checked={item.selected || false}
                                        onChange={() =>
                                          handleCheckboxChange(
                                            index,
                                            "adicional_pack",
                                            item.key
                                          )
                                        }
                                      />
                                      <p
                                        style={{
                                          marginTop: "auto",
                                          marginBottom: "auto",
                                        }}
                                      >
                                        {item.label}
                                      </p>
                                    </div>
                                  ))}
                                </div>
                              </aside>
                            </>
                          )}
                        </div>
                      </Col>
                    </Row>

                    <Col md={12} style={{ marginTop: 10 }}>
                      <label className="form-label">Mulheres do varejo?*</label>
                      <select
                        className="form-control"
                        name="retailWomen"
                        value={guest.retailWomen.value || ""}
                        onChange={(e) => handleGuestChange(index, e)}
                      >
                        <option disabled value="">
                          Selecione
                        </option>
                        <option value="sim">Sim</option>
                        <option value="não">Não</option>
                      </select>
                    </Col>
                  </div>
                </AccordionBody>
              </AccordionItem>
            ))}

            <AccordionItem>
              <AccordionHeader targetId="empresa">
                <h4>Dados da empresa</h4>
              </AccordionHeader>

              <AccordionBody accordionId="empresa">
                <Row>
                  <Col md={6} style={{ marginTop: 10 }}>
                    <label className="form-label">CNPJ*</label>
                    <input
                      className="form-control"
                      type="text"
                      name="cnpj"
                      value={data.Attributes[0]?.Value}
                      onChange={(e) => handleCompanyChange(e)}
                      placeholder="Ex: 12.345.678/0001-90"
                      disabled
                    />
                  </Col>

                  <Col md={6} style={{ marginTop: 10 }}>
                    <label className="form-label">Razão social*</label>
                    <input
                      className="form-control"
                      type="text"
                      name="socialReason"
                      value={data.Attributes[2]?.Value}
                      onChange={(e) => handleCompanyChange(e)}
                      placeholder="Ex: Empresa LTDA"
                      disabled
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={6} style={{ marginTop: 10 }}>
                    <label className="form-label">Nome fantasia*</label>
                    <input
                      className="form-control"
                      type="text"
                      name="fantasyName"
                      value={data.Attributes[3]?.Value}
                      onChange={(e) => handleCompanyChange(e)}
                      placeholder="Ex: Loja do João"
                      disabled
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={6} style={{ marginTop: 10 }}>
                    <label className="form-label">
                      Inscrição Estadual (Opcional)
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="stateRegistration"
                      value={formData.company.stateRegistration.value}
                      onChange={(e) => handleCompanyChange(e)}
                      placeholder="Ex: 123.456.789.123"
                    />
                  </Col>

                  <Col md={6} style={{ marginTop: 10 }}>
                    <label className="form-label">
                      Inscrição Municipal (Opcional)
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="municipalRegistration"
                      value={formData.company.municipalRegistration.value}
                      onChange={(e) => handleCompanyChange(e)}
                      placeholder="Ex: 987.654.321"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={6} style={{ marginTop: 10 }}>
                    <label className="form-label">CEP*</label>
                    <input
                      className="form-control"
                      type="text"
                      name="cepCompany"
                      value={formatCep(formData.company.cepCompany.value)}
                      onChange={handleCompanyChange}
                      placeholder="Ex: 12345-678"
                    />
                  </Col>

                  <Col md={6} style={{ marginTop: 10 }}>
                    <label className="form-label">Endereço*</label>
                    <input
                      className="form-control"
                      type="text"
                      name="address"
                      value={formData.company.address.value}
                      onChange={handleCompanyChange}
                      placeholder="Ex: Rua A, 123"
                    />
                  </Col>
                </Row>

                {/* <Row>
                  <Col md={6} style={{ marginTop: 10 }}>
                    <label className="form-label">Número*</label>

                    <input
                      className="form-control"
                      type="text"
                      name="state"
                      value={formData.company.state.value}
                      onChange={handleCompanyChange}
                      disabled
                      readOnly
                    />
                  </Col>

                  <Col md={6} style={{ marginTop: 10 }}>
                    <label className="form-label">Complemento*</label>
                    <input
                      className="form-control"
                      type="text"
                      name="cityCompany"
                      value={formData.company.cityCompany.value}
                      onChange={handleCompanyChange}
                      placeholder="Ex: São Paulo"
                    />
                  </Col>
                </Row> */}

                <Row>
                  <Col md={6} style={{ marginTop: 10 }}>
                    <label className="form-label">Cidade*</label>
                    <input
                      className="form-control"
                      type="text"
                      name="cityCompany"
                      value={formData.company.cityCompany.value}
                      onChange={handleCompanyChange}
                      placeholder="Ex: São Paulo"
                    />
                  </Col>

                  <Col md={6} style={{ marginTop: 10 }}>
                    <label className="form-label">Estado*</label>

                    <input
                      className="form-control"
                      type="text"
                      name="state"
                      value={formData.company.state.value}
                      onChange={handleCompanyChange}
                      disabled
                      readOnly
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={6} style={{ marginTop: 10 }}>
                    <label className="form-label">País*</label>

                    <input
                      className="form-control"
                      type="text"
                      name="pais"
                      value={formData.company.pais.value}
                      onChange={handleCompanyChange}
                      disabled
                      readOnly
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={6} style={{ marginTop: 10 }}>
                    <label className="form-label">Telefone*</label>
                    <input
                      className="form-control"
                      type="text"
                      name="phone1"
                      value={formatPhoneNumber(formData.company.phone1.value)}
                      onChange={(e) => handleCompanyChange(e)}
                      placeholder="Ex: (11) 1234-5678"
                      maxLength={15}
                    />
                  </Col>

                  <Col md={6} style={{ marginTop: 10 }}>
                    <label className="form-label">Ramal (Opcional)</label>
                    <input
                      className="form-control"
                      type="text"
                      name="phone2"
                      value={formData.company.phone2.value}
                      onChange={(e) => handleCompanyChange(e)}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={6} style={{ marginTop: 10 }}>
                    <label className="form-label">Resp. financeiro*</label>
                    <input
                      className="form-control"
                      type="text"
                      name="financialResponsible"
                      value={formData.company.financialResponsible.value}
                      placeholder="Ex: Maria Oliveira"
                      onChange={(e) => handleCompanyChange(e)}
                    />
                  </Col>

                  <Col md={6} style={{ marginTop: 10 }}>
                    <label className="form-label">E-mail*</label>
                    <input
                      className="form-control"
                      type="text"
                      name="email"
                      value={formData.company.email.value}
                      onChange={(e) => handleCompanyChange(e)}
                      placeholder="Ex: contato@empresa.com.br"
                    />
                  </Col>
                </Row>
              </AccordionBody>
            </AccordionItem>
          </UncontrolledAccordion>
          <div className="div-btn" style={{ padding: "0 15px" }}>
            <button
              type="submit"
              className="a-btn-submit"
              disabled={loading || sent}
              style={{
                backgroundColor: loading
                  ? "gray"
                  : sent
                  ? "#f44e3f"
                  : "#51d651",
              }}
            >
              {loading ? "Enviando" : sent ? "Enviado" : "Enviar"}
            </button>
          </div>
        </form>
      </section>
    </>
  );
}
