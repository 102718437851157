import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader, Row, Col } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

export default function ModalNewAir(props) {
  const { isOpen, toggle, flightData, onSubmit } = props;
  const [flight, setFlight] = useState(flightData || {});

  const [warningAlert, setWarningAlert] = useState("");
  const [successAlert, setSuccessAlert] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFlight((prevData) => ({
      ...prevData,
      [name]: { value: value, id: prevData[name]?.id ?? 0 },
    }));
  };

  async function verifyErrors() {
    if (!flight.departureAirport.value) {
      return "O campo Aeroporto de partida (ida) é obrigatório";
    } else if (!flight.departureCIA.value) {
      return "O campo CIA (ida) é obrigatório";
    } else if (!flight.departureLocator.value) {
      return "O campo Localizador (ida) é obrigatório";
    } else if (!flight.departureDate.value) {
      return "O campo Data de partida (ida) é obrigatório";
    } else if (!flight.departureTime.value) {
      return "O campo Horário de partida (ida) é obrigatório";
    } else if (!flight.arrivalAirport.value) {
      return "O campo Aeroporto de chegada (ida) é obrigatório";
    } else if (!flight.arrivalDate.value) {
      return "O campo Data de chegada (ida) é obrigatório";
    } else if (!flight.returnDepartureAirport.value) {
      return "O campo Aeroporto de partida (volta) é obrigatório";
    } else if (!flight.returnCIA.value) {
      return "O campo CIA (volta) é obrigatório";
    } else if (!flight.returnLocator.value) {
      return "O campo Localizador (volta) é obrigatório";
    } else if (!flight.returnDepartureDate.value) {
      return "O campo Data de partida (volta) é obrigatório";
    } else if (!flight.returnDepartureTime.value) {
      return "O campo Horário de partida (volta) é obrigatório";
    } else if (!flight.returnArrivalAirport.value) {
      return "O campo Aeroporto de chegada (volta) é obrigatório";
    } else if (!flight.returnArrivalDate.value) {
      return "O campo Data de chegada (volta) é obrigatório";
    } else return null;
  }

  async function submitForm(e) {
    e.preventDefault();
    var error = await verifyErrors();

    if (error) {
      setWarningAlert(error);
      return;
    }

    setSuccessAlert(true);
    onSubmit(flight);
  }

  return (
    <>
      {warningAlert && (
        <SweetAlert
          title="Alerta"
          warning
          onConfirm={() => setWarningAlert("")}
        >
          {warningAlert}
        </SweetAlert>
      )}

      {successAlert && (
        <SweetAlert
          title="Sucesso"
          success
          onConfirm={() => {
            toggle();
            setSuccessAlert(false);
          }}
        >
          Dados do aéreo salvos com sucesso!
        </SweetAlert>
      )}

      <Modal isOpen={isOpen} scrollable style={{ borderRadius: 0 }}>
        <ModalHeader toggle={toggle}>Informações do Aéreo</ModalHeader>
        <ModalBody>
          <form onSubmit={submitForm}>
            <Row>
              <h4>Informações da ida</h4>
            </Row>

            <Row style={{ marginTop: 10 }}>
              <Col md={12}>
                <label className="form-label">Aeroporto de partida*</label>
                <input
                  className="form-control"
                  type="text"
                  name="departureAirport"
                  value={flight.departureAirport.value || ""}
                  onChange={handleChange}
                />
              </Col>
            </Row>

            <Row style={{ marginTop: 10 }}>
              <Col md={6}>
                <label className="form-label">CIA*</label>
                <input
                  className="form-control"
                  type="text"
                  name="departureCIA"
                  value={flight.departureCIA.value || ""}
                  onChange={handleChange}
                />
              </Col>

              <Col md={6}>
                <label className="form-label">Localizador*</label>
                <input
                  className="form-control"
                  type="text"
                  name="departureLocator"
                  value={flight.departureLocator.value || ""}
                  onChange={handleChange}
                />
              </Col>
            </Row>

            <Row style={{ marginTop: 10 }}>
              <Col md={6}>
                <label className="form-label">Data de partida*</label>
                <input
                  className="form-control"
                  type="date"
                  name="departureDate"
                  value={flight.departureDate.value || ""}
                  onChange={handleChange}
                />
              </Col>

              <Col md={6}>
                <label className="form-label">Horário de partida*</label>
                <input
                  className="form-control"
                  type="time"
                  name="departureTime"
                  value={flight.departureTime.value || ""}
                  onChange={handleChange}
                />
              </Col>
            </Row>

            <Row style={{ marginTop: 10 }}>
              <Col md={12}>
                <label className="form-label">Aeroporto de chegada*</label>
                <input
                  className="form-control"
                  type="text"
                  name="arrivalAirport"
                  value={flight.arrivalAirport.value || ""}
                  onChange={handleChange}
                />
              </Col>
            </Row>

            <Row style={{ marginTop: 10 }}>
              <Col md={6}>
                <label className="form-label">Data de chegada*</label>
                <input
                  className="form-control"
                  type="date"
                  name="arrivalDate"
                  value={flight.arrivalDate.value || ""}
                  onChange={handleChange}
                />
              </Col>

              <Col md={6}>
                <label className="form-label">Horário de chegada*</label>
                <input
                  className="form-control"
                  type="time"
                  name="arrivalTime"
                  value={flight.arrivalTime.value || ""}
                  onChange={handleChange}
                />
              </Col>
            </Row>

            <Row style={{ marginTop: 30 }}>
              <h4>Informações da volta</h4>
            </Row>

            <Row style={{ marginTop: 10 }}>
              <Col md={12}>
                <label className="form-label">Aeroporto de partida*</label>
                <input
                  className="form-control"
                  type="text"
                  name="returnDepartureAirport"
                  value={flight.returnDepartureAirport.value || ""}
                  onChange={handleChange}
                />
              </Col>
            </Row>

            <Row style={{ marginTop: 10 }}>
              <Col md={6}>
                <label className="form-label">CIA*</label>
                <input
                  className="form-control"
                  type="text"
                  name="returnCIA"
                  value={flight.returnCIA.value || ""}
                  onChange={handleChange}
                />
              </Col>

              <Col md={6}>
                <label className="form-label">Localizador*</label>
                <input
                  className="form-control"
                  type="text"
                  name="returnLocator"
                  value={flight.returnLocator.value || ""}
                  onChange={handleChange}
                />
              </Col>
            </Row>

            <Row style={{ marginTop: 10 }}>
              <Col md={6}>
                <label className="form-label">Data de partida*</label>
                <input
                  className="form-control"
                  type="date"
                  name="returnDepartureDate"
                  value={flight.returnDepartureDate.value || ""}
                  onChange={handleChange}
                />
              </Col>

              <Col md={6}>
                <label className="form-label">Horário de partida*</label>
                <input
                  className="form-control"
                  type="time"
                  name="returnDepartureTime"
                  value={flight.returnDepartureTime.value || ""}
                  onChange={handleChange}
                />
              </Col>
            </Row>

            <Row style={{ marginTop: 10 }}>
              <Col md={12}>
                <label className="form-label">Aeroporto de chegada*</label>
                <input
                  className="form-control"
                  type="text"
                  name="returnArrivalAirport"
                  value={flight.returnArrivalAirport.value || ""}
                  onChange={handleChange}
                />
              </Col>
            </Row>

            <Row style={{ marginTop: 10 }}>
              <Col md={6}>
                <label className="form-label">Data de chegada*</label>
                <input
                  className="form-control"
                  type="date"
                  name="returnArrivalDate"
                  value={flight.returnArrivalDate.value || ""}
                  onChange={handleChange}
                />
              </Col>

              <Col md={6}>
                <label className="form-label">Horário de chegada*</label>
                <input
                  className="form-control"
                  type="time"
                  name="returnTime"
                  value={flight.returnTime.value || ""}
                  onChange={handleChange}
                />
              </Col>
            </Row>

            <div className="div-btn">
              <button type="submit" className="a-btn-submit">
                Salvar
              </button>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
}
